/* eslint-disable */
import * as wasm from "./irr_wasm_bg.wasm";

const heap = new Array(32);

heap.fill(undefined);

heap.push(undefined, null, true, false);

let stack_pointer = 32;

function addBorrowedObject(obj) {
  if (stack_pointer == 1) throw new Error("out of js stack");
  heap[--stack_pointer] = obj;
  return stack_pointer;
}
/**
 * @param {any} js_array
 * @param {number} guess
 * @returns {number}
 */
export function internal_rate_of_return(js_array, guess) {
  try {
    return wasm.internal_rate_of_return(addBorrowedObject(js_array), guess);
  } finally {
    heap[stack_pointer++] = undefined;
  }
}

let WASM_VECTOR_LEN = 0;

let cachedTextEncoder = new TextEncoder("utf-8");

let cachegetUint8Memory = null;
function getUint8Memory() {
  if (
    cachegetUint8Memory === null ||
    cachegetUint8Memory.buffer !== wasm.memory.buffer
  ) {
    cachegetUint8Memory = new Uint8Array(wasm.memory.buffer);
  }
  return cachegetUint8Memory;
}

let passStringToWasm;
if (typeof cachedTextEncoder.encodeInto === "function") {
  passStringToWasm = function(arg) {
    let size = arg.length;
    let ptr = wasm.__wbindgen_malloc(size);
    let writeOffset = 0;
    while (true) {
      const view = getUint8Memory().subarray(ptr + writeOffset, ptr + size);
      const { read, written } = cachedTextEncoder.encodeInto(arg, view);
      writeOffset += written;
      if (read === arg.length) {
        break;
      }
      arg = arg.substring(read);
      ptr = wasm.__wbindgen_realloc(ptr, size, (size += arg.length * 3));
    }
    WASM_VECTOR_LEN = writeOffset;
    return ptr;
  };
} else {
  passStringToWasm = function(arg) {
    const buf = cachedTextEncoder.encode(arg);
    const ptr = wasm.__wbindgen_malloc(buf.length);
    getUint8Memory().set(buf, ptr);
    WASM_VECTOR_LEN = buf.length;
    return ptr;
  };
}

let cachegetUint32Memory = null;
function getUint32Memory() {
  if (
    cachegetUint32Memory === null ||
    cachegetUint32Memory.buffer !== wasm.memory.buffer
  ) {
    cachegetUint32Memory = new Uint32Array(wasm.memory.buffer);
  }
  return cachegetUint32Memory;
}

function getObject(idx) {
  return heap[idx];
}

export function __wbindgen_json_serialize(idx, ptrptr) {
  const ptr = passStringToWasm(JSON.stringify(getObject(idx)));
  getUint32Memory()[ptrptr / 4] = ptr;
  return WASM_VECTOR_LEN;
}

let heap_next = heap.length;

function dropObject(idx) {
  if (idx < 36) return;
  heap[idx] = heap_next;
  heap_next = idx;
}

export function __wbindgen_object_drop_ref(i) {
  dropObject(i);
}
